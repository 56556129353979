import { Observable } from 'rxjs';
import { DialogComponent, DialogContainerData, DialogService } from 'shared';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ComponentType } from '@angular/cdk/overlay';
import { WebDialogContainerComponent } from '@web/app/components/web-dialog-container/web-dialog-container.component';

@Injectable({ providedIn: 'root' })
export class WebDialogService implements DialogService {
  constructor(private matDialog: MatDialog, private matSheet: MatBottomSheet) {}

  openDialog<C extends DialogComponent<D, R>, D, R>(
    component: ComponentType<C>,
    data: D | undefined
  ): Observable<R | undefined> {
    const dialogContainerData: DialogContainerData<C, D> = {
      componentType: component,
      data: data
    };
    if (window.innerWidth >= 1024) {
      return this.openRegularDialog(dialogContainerData);
    }
    return this.openSheet(dialogContainerData);
  }

  private openSheet<C, D, R>(dialogContainerData: DialogContainerData<C, D>): Observable<R> {
    const sheet = this.matSheet.open(WebDialogContainerComponent, {
      data: dialogContainerData
    });
    return sheet.afterDismissed();
  }

  private openRegularDialog<C, D, R>(dialogContainerData: DialogContainerData<C, D>): Observable<R> {
    const dialog = this.matDialog.open(WebDialogContainerComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: dialogContainerData
    });
    return dialog.afterClosed();
  }
}
