import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit() {
    this.addNotranslate();
  }

  private addNotranslate() {
    const elements = document.querySelectorAll('.mat-outlined');
    elements.forEach(element => {
      element.classList.add('notranslate');
      element.setAttribute('translate', 'no');
    });
  }
}
