<form class="flex flex-column" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="p-float-label">
    <input pInputText id="nameInput" formControlName="name" touchedDirty class="p-filled w-full" />
    <label for="nameInput">{{ 'name' | translate }}</label>
  </div>

  <div class="p-float-label">
    <textarea
      pInputTextarea
      formControlName="description"
      [autoResize]="true"
      id="descriptionInput"
      class="p-filled w-full"
      touchedDirty
      rows="2">
    </textarea>
    <label for="descriptionInput">{{ 'description' | translate }}</label>
  </div>

  <h2 class="mb-3 mt-2">Berechtigungen</h2>

  <p-progressSpinner *ngIf="loading" class="mb-2 ml-3"></p-progressSpinner>

  <mat-chip-listbox *ngIf="!loading" [multiple]="true" [selectable]="true">
    <mat-chip-option
      *ngFor="let permission of permissions"
      (click)="selectPermission(permission)"
      (removed)="unselectPermission(permission)"
      [selected]="formGroup.controls.selectedPermissions.value?.includes(permission.id)"
      selectable="true"
      removable="true">
      {{ permission.label }}
      <mat-icon matChipRemove *ngIf="formGroup.controls.selectedPermissions.value?.includes(permission.id)"
        >cancel
      </mat-icon>
    </mat-chip-option>
  </mat-chip-listbox>
</form>
