import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollablePaddingDirective } from '../../directives/srollable-padding.directive';

@NgModule({
  declarations: [ScrollablePaddingDirective],
  imports: [CommonModule],
  exports: [ScrollablePaddingDirective]
})
export class DialogModule {}
