<div class="page">
  <div class="page-tab">
    <a [routerLink]="'..'"> <span class="mat-outlined mr-2">arrow_back</span>Zurück</a>
  </div>
  <div class="page-content">
    <h3 class="m-0">{{ pdf?.name }}</h3>
    <p class="mt-0 mb-2">{{ pdf?.description }}</p>
    <ngx-extended-pdf-viewer *ngIf="pdfBlob" [src]="pdfBlob" height="100%" [useBrowserLocale]="true" [spread]="'even'">
    </ngx-extended-pdf-viewer>
  </div>
</div>
