import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';
import { IUser } from 'shared';

import { UserService } from '../classes/user-service.class';

@Injectable({
  providedIn: 'root'
})
export class MainUserService extends UserService {
  constructor(private readonly _authService: AuthService) {
    super();
  }

  public getUser(): Observable<IUser | undefined> {
    return this._authService.user$.pipe(
      map(user => {
        if (user) {
          const userTemp: IUser = {
            id: user.sub,
            firstName: '',
            lastName: '',
            name: user.name,
            phone: user.phone_number,
            email: user.email,
            picture: user.picture,
            license: undefined
          };
          return userTemp;
        }
        return undefined;
      })
    );
  }
}
