import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject, delay, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

export interface ComponentConfig {
  header: string;
  confirmLabel?: string;
  confirmIcon?: string;
  showConfirmIcon?: boolean;
  confirmSuccessMessage?: string;
  confirmErrorMessage?: string;
  cancelLabel?: string;
  cancelIcon?: string;
  showCancelIcon?: boolean;
  confirmButtonClass?: string;
  cancelButtonClass?: string;
}

@Component({
  template: ''
})
export abstract class DialogComponent<D, R> {
  @Input() data: D | undefined;
  @Input() mobile!: boolean;
  @Output() dismissed: EventEmitter<R | undefined> = new EventEmitter<R | undefined>();
  @Output() onConfirmInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirmSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirmError: EventEmitter<HttpErrorResponse | undefined> = new EventEmitter<
    HttpErrorResponse | undefined
  >();

  public abstract config: ComponentConfig;

  private _confirmProgress: boolean = false;
  private _confirmSuccess: boolean = false;
  private _confirmError: HttpErrorResponse | undefined = undefined;

  public set confirmProgress(value: boolean) {
    this.onConfirmInProgress.emit(value);
    this._confirmProgress = value;
  }

  public get confirmProgress(): boolean {
    return this._confirmProgress;
  }

  public set confirmSuccess(value: boolean) {
    this.onConfirmSuccess.emit(value);
    this._confirmSuccess = value;
  }

  public get confirmSuccess(): boolean {
    return this._confirmSuccess;
  }

  public set confirmError(value: HttpErrorResponse | undefined) {
    this.onConfirmError.emit(value);
    this._confirmError = value;
  }

  public get confirmError(): HttpErrorResponse | undefined {
    return this._confirmError;
  }

  protected dismiss(data?: R) {
    this.dismissed.emit(data);
  }

  public close() {
    this.dismiss();
  }

  public confirm() {
    if (!this.canConfirm()) {
      return;
    }
    this.confirmProgress = true;
    this.confirmSuccess = false;
    this.confirmError = undefined;
    this.getSaveObs().subscribe({
      next: value => {
        this.confirmProgress = false;
        this.confirmSuccess = true;
        this.confirmError = undefined;
        this.confirmSuccessHandle(value);
      },
      error: err => {
        this.confirmProgress = false;
        this.confirmSuccess = false;
        this.confirmError = err;
        this.confirmErrorHandle(err);
      }
    });
  }

  protected canConfirm(): boolean {
    return true;
  }

  protected confirmSuccessHandle(value: R) {
    setTimeout(() => this.dismiss(value), 1500);
  }

  protected confirmErrorHandle(err: HttpErrorResponse) {}

  abstract getSaveObs(): Observable<R>;

  // todo implement possibility to provide templates on dialog component level which are then used by the DialogContainerComponent overriding default templates
  // @ViewChild('headerTemplate', { static: true }) public headerTemplate!: TemplateRef<any>;
  // @ViewChild('cancelTemplate', { static: true }) public cancelTemplate!: TemplateRef<any>;
  // @ViewChild('confirmTemplate', { static: true }) public confirmTemplate!: TemplateRef<any>;
}
