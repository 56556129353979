import { NgModule } from '@angular/core';
import { WebConfirmDialogDirective } from '@web/app/modules/confirm/web-confirm.directive';
import { ConfirmDialogComponent, ConfirmDirective } from 'shared';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TouchedDirtyDirective } from 'shared';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [ConfirmDialogComponent, WebConfirmDialogDirective],
  imports: [CommonModule, ReactiveFormsModule, CommonModule, TouchedDirtyDirective, CheckboxModule, InputTextModule],
  exports: [WebConfirmDialogDirective],
  providers: [
    {
      provide: ConfirmDirective,
      useClass: WebConfirmDialogDirective
    }
  ]
})
export class WebConfirmModule {}
