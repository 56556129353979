import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ErrorService, ResourceService, UserService } from 'data-access';
import {
  AddProjectMember,
  LoadAllProjectMembers,
  RemoveProjectMember,
  selectAllProjectMembers,
  SetProjectMemberRoles
} from 'data-access';
import { selectSelectedOrganization } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { Observable, of } from 'rxjs';
import { IMember, IOrganization, IProject, IRole, IUser, RESOURCE_KEY, USER_KEY } from 'shared';
import { ProjectUserInputComponent } from '../../components/project-user-input/project-user-input.component';

@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss']
})
export class ProjectMembersComponent implements OnInit {
  @ViewChild('memberInputComponent') memberInput!: ProjectUserInputComponent;

  project?: IProject;
  organization: Observable<IOrganization | undefined> = of(undefined);
  roles: IRole[] = [];
  members: IMember[] = [];
  user: Observable<IUser | undefined> = of(undefined);
  newMember?: IMember;

  constructor(
    @Inject(RESOURCE_KEY) private restService: ResourceService,
    private store: Store,
    private errorService: ErrorService,
    @Inject(USER_KEY) private _userService: UserService
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadAllProjectMembers());
    this.store.select(selectAllProjectMembers).subscribe(members => (this.members = members));
    this.organization = this.store.pipe(select(selectSelectedOrganization));
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.restService.getAllRolesObs().subscribe(roles => (this.roles = roles));
    this.user = this._userService.getUser();
  }

  getUnassignedRoles(member: IMember): IRole[] {
    return this.roles.filter(role => {
      return !member.roles.find(memberRole => memberRole.id == role.id);
    });
  }

  addRole(member: IMember, role: IRole) {
    this.restService.postProjectMemberRoleObs(this.project!.id, member.id!, role.id).subscribe({
      error: err => {
        this.errorService.showError('Rolle konnte nicht hinzugefügt werden', err);
      },
      next: updatedMember => {
        this.store.dispatch(
          new SetProjectMemberRoles({
            member: member,
            roles: updatedMember.roles
          })
        );
      }
    });
  }

  removeRole(member: IMember, role: IRole) {
    this.restService.deleteProjectMemberRoleObs(this.project!.id, member.id!, role.id).subscribe({
      error: err => {
        this.errorService.showError('Rolle konnte nicht entfernt werden', err);
      },
      next: () => {
        let newRoles = member.roles.filter(r => r.id != role.id);
        this.store.dispatch(new SetProjectMemberRoles({ member: member, roles: newRoles }));
      }
    });
  }

  removeMember(member: IMember) {
    this.restService.deleteProjectMemberObs(this.project!.id, member.id!).subscribe({
      error: err => {
        this.errorService.showError('Teilnehmer konnte nicht entfernt werden', err);
      },
      next: () => {
        this.store.dispatch(new RemoveProjectMember({ memberId: member.id! }));
      }
    });
  }

  addProjectMember() {
    this.restService.postProjectMember(this.project!.id, this.newMember!.id!, this.newMember!.roles).subscribe({
      error: err => {
        this.errorService.showError('Teilnehmer konnte nicht hinzugefügt werden', err);
      },
      next: member => {
        this.store.dispatch(new AddProjectMember({ member: member }));
        this.memberInput.setUntouched();
        this.newMember = getInitialMember();
      }
    });
  }

  get memberIds(): string[] {
    return this.members.map(member => member.id!);
  }
}

export function getInitialMember(): IMember {
  return {
    id: undefined,
    name: undefined,
    picture: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    roles: [],
    license: undefined
  };
}
