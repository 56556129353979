import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { filter, Observable, take, tap, throwError } from 'rxjs';

import { TokenService } from 'data-access';
import { MANAGEMENT_TOKEN_SERVICE } from 'shared';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class WebManagementInterceptor implements HttpInterceptor {
  constructor(@Inject(MANAGEMENT_TOKEN_SERVICE) private readonly managementTokenService: TokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.managementTokenService.getAccessToken().pipe(
      filter(token => token != undefined),
      take(1),
      switchMap(token => {
        return next.handle(this.addToken(request, token!)).pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              switch (err.status) {
                case 401:
                  return this.handle401Error(request, next);
                default:
                  return throwError(() => err);
              }
            } else {
              return throwError(() => err);
            }
          })
        );
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) {
      return req.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token
        })
      });
    }
    return req;
  }

  protected handle401Error(request: any, next: any): Observable<any> {
    return this.managementTokenService.reAuthorize().pipe(
      filter(token => token != undefined),
      take(1),
      switchMap(token => {
        return next.handle(this.addToken(request, token!));
      })
    );
  }
}
