<div class="entry-wrapper" [class.expanded]="isExpanded">
  <div class="entry" *ngIf="entry" dropzone (onFilesDropped)="imagePreviewList.openImageUploadPopover($event)">
    <div class="entry-header">
      <div class="left-side">
        <p class="company-title">{{ entry.company?.name }}</p>
        <p class="shift-title">
          {{ entry.shiftStart | date : 'HH:mm' }} -
          {{ entry.shiftEnd | date : 'HH:mm' }}
        </p>
      </div>

      <div class="flex flex-row align-items-center">
        <app-creator-chip [creator]="entry.creator" class="mr-2"></app-creator-chip>

        <button
          pButton
          class="p-button-text"
          [popoverTrigger]="controlMenuTemplate"
          [positionX]="['end', 'end']"
          [positionY]="['bottom', 'top']"
          [closeOnClickOutside]="true"
          *ngIf="!entry.closed && isCreator"
          icon="mat-outlined mat-edit-square"></button>

        <button
          pButton
          class="p-button-text"
          disabled
          *ngIf="!entry.closed && !isCreator"
          icon="mat-outlined mat-lock-open"></button>

        <button pButton class="p-button-text" disabled *ngIf="entry.closed" icon="mat-outlined mat-lock"></button>
      </div>
    </div>
    <!-- todo add entry reference back to image preview list-->
    <div class="entry-content-flex">
      <image-preview-list-component
        #imagePreviewList
        [imageIds]="imageIds"
        [date]="entry.date"
        [entryId]="entry.id"
        [class.expanded]="isExpanded">
      </image-preview-list-component>

      <div class="entry-content">
        <div [id]="'infoText'" class="entry-text" [class.hidden]="!isExpanded">
          <div class="weather">
            <p>
              <label>{{ 'weather' | translate }}:</label> {{ entry.weather }}
            </p>
            <p>{{ entry.minTemp }}°C - {{ entry.maxTemp }}°C</p>
          </div>
          <div class="personal">
            <p>
              <label>{{ 'workforce' | translate }}:</label> {{ entry.workforce }}
            </p>
            <p>
              <label>{{ 'sipo' | translate }}:</label> {{ entry.sipo }}
            </p>
          </div>
        </div>

        <div
          [id]="'otherMeasuresText'"
          #otherMeasuresText
          class="entry-text"
          [class.hidden]="!isExpanded"
          *ngIf="entry.otherMeasures?.trim() != ''">
          <label>{{ 'other-measurements' | translate }}:</label>
          <p>{{ entry.otherMeasures }}</p>
        </div>

        <div [id]="'doneWorkText'" #doneWorkText class="entry-text" [class.expanded]="isExpanded">
          <label>{{ 'work-performed' | translate }}:</label>
          <p>{{ entry.doneWork }}</p>
        </div>

        <div [id]="'machinesText'" class="entry-text" [class.hidden]="!isExpanded" *ngIf="entry.machines?.trim() != ''">
          <label>{{ 'equipment-component-materials' | translate }}:</label>
          <p>{{ entry.machines }}</p>
        </div>

        <div [id]="'supplyText'" class="entry-text" [class.hidden]="!isExpanded" *ngIf="entry.supply?.trim() != ''">
          <label>{{ 'supply-and-disposal' | translate }}:</label>
          <p>{{ entry.supply }}</p>
        </div>

        <button id="moreInfoButton" (click)="isExpanded = !isExpanded">
          <span class="mat-outlined" [class.-rotate-90]="isExpanded">chevron_right</span>
          <p>{{ isExpanded ? 'Weniger' : 'Mehr' }} {{ 'show' | translate }}</p>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #controlMenuTemplate>
  <popover-container>
    <div class="flex flex-column mx-1 gap-1">
      <button
        pButton
        class="p-button-text"
        (onConfirmed)="closeEntry()"
        confirmTitle="Eintrag abschließen"
        confirmMessage="Durch Abschließen des Eintrags kann dieser nicht mehr bearbeitet werden"
        confirmLabel="Abschließen"
        [confirmToggle]="true">
        <span class="mat-outlined">lock</span>
        <p class="ml-1">Festschreiben</p>
      </button>
      <hr />
      <button pButton class="p-button-text" autoClosePopover (click)="editEntry()">
        <span class="mat-outlined">edit_square</span>
        <p class="ml-1">{{ 'edit' | translate }}</p>
      </button>
      <button pButton class="p-button-text" autoClosePopover (click)="imagePreviewList.triggerImageInput()">
        <span class="mat-outlined">photo_camera</span>
        <p class="ml-1">{{ 'photo' | translate }}</p>
      </button>
      <hr />
      <button
        pButton
        class="p-button-text p-button-danger"
        (onConfirmed)="deleteEntry()"
        confirmTitle="Eintrag löschen"
        confirmMessage="Diese Aktion kann nicht rückgängig gemacht werden"
        confirmLabel="Löschen"
        [confirmDanger]="true"
        confirmChallenge="LÖSCHEN">
        <span class="mat-outlined">delete</span>
        <p class="ml-1">{{ 'delete' | translate }}</p>
      </button>
    </div>
  </popover-container>
</ng-template>
