import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DialogComponent } from 'shared';

@Component({
  template: ''
})
export abstract class FormDialogComponent<
    D,
    R,
    F extends {
      [K in keyof F]: AbstractControl<any>;
    }
  >
  extends DialogComponent<D, R>
  implements OnInit
{
  formGroup?: FormGroup<F>;

  protected override canConfirm(): boolean {
    return this.formGroup?.valid ?? false;
  }

  public override confirm() {
    if (!this.canConfirm()) {
      this.formGroup?.markAllAsTouched();
      return;
    }
    super.confirm();
  }

  ngOnInit() {
    this.formGroup = this.buildFormGroup();
  }

  abstract buildFormGroup(): FormGroup<F>;
}
