import { Component, OnInit } from '@angular/core';
import { IPdf, IProject, PdfStatusEnum } from 'shared';
import { catchError, EMPTY, filter, Observable, switchMap, tap } from 'rxjs';
import {
  AddPdfExport,
  ErrorService,
  PdfService,
  RemovePdfExport,
  ResourceService,
  selectSelectedProject,
  UpdatePdfExport,
  UpdatePdfGenerationProgress
} from 'data-access';
import { Store } from '@ngrx/store';

@Component({
  template: ''
})
export abstract class BaseProjectExportItemComponent implements OnInit {
  pdf?: IPdf;
  project?: IProject;

  protected constructor(
    protected store: Store,
    protected pdfService: PdfService,
    protected resourceService: ResourceService,
    protected errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.pdfSelector().subscribe(pdf => {
      if (!this.pdf && pdf && !(pdf.status === PdfStatusEnum.complete || pdf.status === PdfStatusEnum.failed)) {
        this.pdfService
          .getPdfGenerationStatus(this.project!, pdf!.id)
          .pipe(filter(status => status !== undefined))
          .subscribe(status => {
            this.store.dispatch(new UpdatePdfGenerationProgress(pdf.id, status ?? PdfStatusEnum.failed));
          });
      }
      this.pdf = pdf;
    });
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
  }
  get outdated(): boolean {
    return this.pdf ? this.pdf.outdated! : false;
  }

  get refreshButtonLabel(): string {
    return this.loading ? this.pdf?.status ?? 'Erstellen' : 'Aktualisieren';
  }

  get loading(): boolean {
    return this.pdf ? !(this.pdf.status === PdfStatusEnum.complete || this.pdf.status === PdfStatusEnum.failed) : false;
  }

  refresh() {
    this.refreshObs()
      .pipe(
        catchError(err => {
          this.errorService.showError('PDF konnte nicht erzeugt werden', err);
          return EMPTY;
        }),
        tap(pdf => {
          if (this.pdf?.id) {
            this.store.dispatch(new UpdatePdfExport(pdf));
          } else {
            this.store.dispatch(new AddPdfExport(pdf));
          }
        }),
        switchMap(pdf => {
          return this.pdfService.getPdfGenerationStatus(this.project!, pdf.id);
        })
      )
      .subscribe(status => {
        this.store.dispatch(new UpdatePdfGenerationProgress(this.pdf!.id, status ?? PdfStatusEnum.failed));
      });
  }

  get isComplete() {
    return this.pdf ? this.pdf.status === PdfStatusEnum.complete : false;
  }

  abstract get title(): string | undefined;
  abstract get description(): string | undefined;
  abstract refreshObs(): Observable<IPdf>;
  abstract pdfSelector(): Observable<IPdf | undefined>;

  downloadFile() {
    this.resourceService.getPdfBlob(this.project!, this.pdf!.id).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = '';
      anchor.href = url;
      anchor.click();
    });
  }

  showPdfDetails() {
    throw new Error('NOT IMPLEMENTED');
  }

  deletePdf() {
    this.resourceService.deletePdf(this.project!.id, this.pdf!.id).subscribe({
      next: () => {
        this.store.dispatch(new RemovePdfExport(this.pdf!.id));
      },
      error: err => {
        this.errorService.showError('PDF konnte nicht gelöscht werden', err);
      }
    });
  }

  get generationFailed(): boolean {
    return this.pdf?.status === PdfStatusEnum.failed;
  }
}
