import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreMembersRoutingModule } from './core-members-routing.module';
import { MatSortModule } from '@angular/material/sort';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';

import { MembersPage } from './components/members/members.page';
import { ListComponent } from './components/list/list.component';
import { InviteComponent } from './components/invite/invite.component';
import { RolesComponent } from './modules/roles/roles.component';
import { EditRoleDialogComponent } from './modules/roles/edit-role-dialog/edit-role-dialog.component';
import { SharedModule, TouchedDirtyDirective } from 'shared';
import { FeatureDiaryModule } from '../diary/feature-diary.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreMembersRoutingModule,
    MatSortModule,
    SharedPageModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDividerModule,
    FeatureDiaryModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    SharedModule,
    ProgressSpinnerModule,
    TouchedDirtyDirective
  ],
  declarations: [MembersPage, ListComponent, InviteComponent, RolesComponent, EditRoleDialogComponent]
})
export class CoreMembersModule {}
