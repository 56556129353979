import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddPdfExport, ResourceService, selectPdfById, selectSelectedProject } from 'data-access';
import { IPdf, IProject, RESOURCE_KEY } from 'shared';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, filter, map, Observable, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-project-export-viewer',
  templateUrl: './project-export-viewer.component.html',
  styleUrls: ['./project-export-viewer.component.scss']
})
export class ProjectExportViewerComponent implements OnInit {
  protected pdf?: IPdf;
  protected project?: IProject;
  protected pdfBlob?: Blob;
  constructor(
    private store: Store,
    @Inject(RESOURCE_KEY)
    private resourceService: ResourceService,
    private activatedRouted: ActivatedRoute
  ) {
    combineLatest([this.activatedRouted.paramMap, this.store.select(selectSelectedProject)])
      .pipe(
        map<[ParamMap, IProject | undefined], [string | null, IProject | undefined]>(([params, project]) => [
          params.get('pdfId'),
          project
        ]),
        filter(([pdfId, project]) => !!project && !!pdfId),
        tap(console.log),
        switchMap(([pdfId, project]) => {
          this.project = project!;
          return this.store.select(selectPdfById(pdfId!)).pipe(
            switchMap(pdf => {
              if (!pdf) {
                return this.resourceService.getPdf(project!.id, pdfId!).pipe(
                  tap(fetchedPdf => {
                    this.store.dispatch(new AddPdfExport(fetchedPdf));
                  })
                );
              }
              return of(pdf);
            })
          );
        }),
        tap(pdf => (this.pdf = pdf)),
        switchMap(pdf => {
          return this.resourceService.getPdfBlob(this.project!, pdf.id);
        })
      )
      .subscribe(blob => {
        this.pdfBlob = blob;
      });
  }

  ngOnInit(): void {}
}
