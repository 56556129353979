import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResourceService, UserService } from 'data-access';
import { combineLatest, map } from 'rxjs';
import { environment, ILicense, IOrganization, IUser, RESOURCE_KEY, USER_KEY } from 'shared';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  organization: IOrganization | undefined = undefined;
  hasError = false;
  loading = true;
  users: IUser[] = [];
  filteredUsers: IUser[] = [];
  licenses: ILicense[] = [];
  memberSearchForm: FormControl = new FormControl();
  loggedInUser?: IUser;

  isProduction = environment.production;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(USER_KEY) private userService: UserService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService
  ) {}

  ngOnInit() {
    this.organization = this.activatedRoute.parent?.parent?.parent?.snapshot.data['organization'];
    this.memberSearchForm.valueChanges.pipe(map((term: string) => term.toLowerCase())).subscribe(term => {
      this.filteredUsers = this.users?.filter(
        user => user.email?.toLowerCase().includes(term) || user.name?.toLowerCase().includes(term)
      );
    });
    this.loadPageData();
    this.userService.getUser().subscribe(user => {
      if (user) {
        this.loggedInUser = user;
      }
    });
  }

  loadPageData() {
    this.loading = true;
    this.hasError = false;
    combineLatest([this.resourceService.getUsers(), this.resourceService.getLicenses(this.organization!.id)]).subscribe(
      {
        error: () => {
          this.users = [];
          this.filteredUsers = [];
          this.licenses = [];
          this.hasError = true;
          this.loading = false;
        },
        next: ([users, licenses]) => {
          users.sort(this.sortMembers);
          this.users = users;
          this.filteredUsers = this.users;
          this.licenses = licenses;
          this.hasError = false;
          this.loading = false;
        }
      }
    );
  }

  getMembership(licenseId: string): 'Intern' | 'Extern' | undefined {
    if (this.licenses == undefined) {
      return undefined;
    }
    if (this.licenses.find(license => license.id == licenseId)) {
      return 'Intern';
    }
    return 'Extern';
  }

  hasLicense(user: IUser): boolean {
    return this.licenses.find(license => license.id == user.id) != undefined;
  }

  sortMembers = (m1: IUser, m2: IUser): number => {
    if (m1.id === this.loggedInUser?.id) {
      return -1;
    }
    return m1.name!.localeCompare(m2.name!);
  };

  blockUser(user: IUser) {}

  editRoles(user: IUser) {}

  removeLicense(user: IUser) {}

  assignLicense(user: IUser) {}
}
