import { ComponentRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentConfig, DialogComponent } from '../dialog/dialog.component';
import { Component } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-container',
  template: ''
})
export abstract class DialogContainerComponent<C extends DialogComponent<D, R>, D, R> implements OnInit {
  @ViewChild('dialogContainer', { read: ViewContainerRef, static: true }) private container!: ViewContainerRef;
  protected componentType!: ComponentType<C>;
  protected data: D | undefined;
  protected config?: ComponentConfig;
  protected confirmInProgress: boolean = false;
  protected confirmSuccess: boolean = false;
  protected confirmError: HttpErrorResponse | undefined = undefined;

  innerWidth?: number;
  get isMobile(): boolean {
    return innerWidth < 1024;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    this.componentRef!.instance.mobile = this.isMobile;
  }

  protected constructor() {}

  componentRef?: ComponentRef<C>;

  private createComponent(): void {
    this.container.clear();
    this.componentRef = this.container.createComponent(this.componentType, {});
    // pass component data & subscribe to dismiss event
    this.componentRef.instance.data = this.data;
    this.componentRef.instance.dismissed.subscribe((data: R | undefined) => this.dismiss(data));
    this.componentRef.instance.onConfirmSuccess.subscribe(confirmSuccess => (this.confirmSuccess = confirmSuccess));
    this.componentRef.instance.onConfirmError.subscribe(confirmError => (this.confirmError = confirmError));
    this.componentRef.instance.onConfirmInProgress.subscribe(
      confirmInProgress => (this.confirmInProgress = confirmInProgress)
    );
    this.config = this.componentRef.instance.config;
  }

  // executed while ngInit of the parent component. Has to set:
  // 1. data: D | undefined
  // 2. componentType: ComponentType<C>
  abstract init(): void;

  abstract dismiss(data?: R): void;

  ngOnInit(): void {
    this.init();
    this.createComponent();
    this.innerWidth = window.innerWidth;
    this.componentRef!.instance.mobile = this.isMobile;
  }

  close() {
    this.componentRef?.instance.close();
  }

  confirm() {
    this.componentRef?.instance.confirm();
  }

  protected get confirmIcon(): string {
    return `mat-outlined ${this.config?.confirmIcon ? this.config?.confirmIcon : 'mat-check'}`;
  }

  protected get cancelIcon(): string {
    return `mat-outlined ${this.config?.cancelIcon ? this.config?.cancelIcon : 'mat-close'}`;
  }

  protected clearConfirmError() {
    this.componentRef!.instance.confirmError = undefined;
  }

  protected clearConfirmSuccess() {
    this.componentRef!.instance.confirmSuccess = false;
  }
}
