import { DatePipe } from '@angular/common';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { selectSelectedOrganization } from 'data-access';
import { catchError, EMPTY, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import {
  environment,
  IAuthority,
  ICompany,
  ICompany as TestCompany,
  IDiaryEntry,
  IDiaryEntryIdsResponse,
  IEntry,
  IEntryDto,
  IEntryInterface,
  IEntryRequestType,
  IFCMToken,
  IGeneratePdfRequest,
  IImage,
  IImage as StateImage,
  IImageEntry,
  IImageEntryRequest,
  IInvitation,
  IInvitationPostRequest,
  ILicense,
  IMember as MemberState,
  IMember,
  INotification,
  IOrganization,
  IPage,
  IPatchProjectRequest,
  IPdf,
  IPdfTemplate,
  IPermission,
  IPostProjectRequest,
  IProject,
  IProject as ProjectOld,
  IProjectQueryParams,
  IRole,
  IUser
} from 'shared';

import { ResourceHttpClient } from '../interceptors/resource-server-client.service';
import { BuildDirection } from './diary-object.service';
import { ManagementHttpClient } from '../interceptors/manangement-server-client.service';
import { ResourceService } from '../classes/resource-service.class';

export function buildHttpParams(obj: any) {
  let params = new HttpParams();
  for (const k in obj) {
    if (obj[k] != undefined) {
      params = params.set(k, obj[k]);
    }
  }
  return params;
}

@Injectable({
  providedIn: 'root'
})
export class ApiResourceService implements ResourceService {
  readonly MANAGEMENT_BASE_URL: string = environment.managementServerBaseURL;
  organizationObs: Observable<IOrganization | undefined>;
  constructor(
    private readonly httpClient: ManagementHttpClient,
    private resourceHttpClient: ResourceHttpClient,
    public datePipe: DatePipe
  ) {
    const store = inject(Store<AppState>);
    this.organizationObs = store.select(selectSelectedOrganization).pipe(filter(org => org != undefined));
  }

  postAbstractEntry<TRequest extends IEntryRequestType, TResponse extends IEntryInterface>(
    projectId: string,
    entry: TRequest
  ): Observable<TResponse> {
    return this.resourceHttpClient.post<TResponse>(`/projects/${projectId}/diary`, entry);
  }
  patchAbstractEntry<TRequest extends IEntryRequestType, TResponse extends IEntryInterface>(
    projectId: string,
    entryId: string,
    entry: TRequest
  ): Observable<TResponse> {
    return this.resourceHttpClient.patch<TResponse>(`/projects/${projectId}/diary/${entryId}`, entry);
  }

  public getAbstractEntries(
    projectId: string,
    queryDate: Date,
    direction: BuildDirection,
    page: number,
    size: number
  ): Observable<IPage<IEntryInterface>> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', page);
    params = params.set('size', size);
    params = params.set('sort', direction == BuildDirection.UP ? 'date,asc' : 'date,desc');
    params = params.set('paginated', true);

    let body: any;
    if ((direction = BuildDirection.UP)) {
      body = {
        until: this.datePipe.transform(queryDate, 'yyyy-MM-dd')!
      };
    } else {
      body = {
        before: this.datePipe.transform(queryDate, 'yyyy-MM-dd')!
      };
    }
    return this.resourceHttpClient.post<IPage<IEntryInterface>>(`/projects/${projectId}/diary/filter`, body, {
      params: params
    });
  }

  getEntryFile(projectId: string, entryId: string, preview: boolean): Observable<Blob> {
    return this.resourceHttpClient.get(`/projects/${projectId}/diary/${entryId}/file`, {
      responseType: 'blob',
      params: { preview: preview }
    });
  }

  public getEntry(projectId: string, entryId: string): Observable<IEntry> {
    throw new Error('Method not implemented.');
  }

  public getInstanceName(): string {
    return 'ApiResourceService';
  }

  public generateTemplatePdf(projectId: string, templateId: string): Observable<IPdf> {
    return this.resourceHttpClient.post<IPdf>(`/projects/${projectId}/templates/${templateId}`, {});
  }

  // ----------------------------- ORGANIZATIONS SECTION START ----------------------------------

  public getOrganizations(): Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(this.MANAGEMENT_BASE_URL + '/organizations');
    // return this.httpClient.get<IOrganization[]>(https://www.google.de/?hl=de);
  }

  public getOrganizationStatus(organization: IOrganization): Observable<any> {
    return this.resourceHttpClient.get<any>(organization.api + 'status');
  }

  // ----------------------------- ORGANIZATIONS SECTION END ----------------------------------

  // ----------------------------- PROJECTS SECTION START ----------------------------------

  public getProjects(queryParams?: IProjectQueryParams): Observable<IProject[]> {
    console.log('get projects');
    let params: HttpParams = new HttpParams();
    if (queryParams) {
      params = buildHttpParams(queryParams);
    }
    return this.resourceHttpClient.get<IProject[]>('/projects', { params: params });
  }

  public getOrganizationProjects(organization: IOrganization): Observable<IProject[]> {
    return this.resourceHttpClient.get<IProject[]>(organization.api + 'projects');
  }

  public getProjectObs(organization: IOrganization, projectId: string): Observable<IProject> {
    return this.resourceHttpClient.get<IProject>(organization.api + 'projects/' + projectId);
  }

  public postProjectObs(project: IPostProjectRequest): Observable<IProject> {
    return this.resourceHttpClient.post<IProject>('projects', project);
  }

  public patchProject(projectId: string, project: IPatchProjectRequest): Observable<IProject> {
    return this.resourceHttpClient.patch<IProject>('/projects/' + projectId, project);
  }

  public getProject(projectId: string): Observable<IProject> {
    return this.resourceHttpClient.get<IProject>('/projects/' + projectId);
  }

  // ----------------------------- PROJECTS SECTION END ----------------------------------

  public getProjectCompanies(projectId: string, query?: string): Observable<ICompany[]> {
    let params = new HttpParams();
    if (query) {
      params = params.set('query', query);
    }
    return this.resourceHttpClient.get<ICompany[]>(`/projects/${projectId}/companies`, { params: params });
  }

  // ----------------------------- COMPANIES SECTION START ----------------------------------

  public getProjectCompaniesObs(projectId: string): Observable<ICompany[]> {
    return this.resourceHttpClient.get<ICompany[]>('/projects/' + projectId + '/companies');
  }

  // ----------------------------- COMPANIES SECTION END ----------------------------------

  // ----------------------------- MEMBERS SECTION START ----------------------------------

  public getProjectMembersObs(projectId: string): Observable<MemberState[]> {
    return this.resourceHttpClient.get<MemberState[]>('/projects/' + projectId + '/members');
  }

  public deleteProjectMemberObs(projectId: string, memberId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/projects/' + projectId + '/members/' + memberId);
  }

  public postProjectMember(projectId: string, memberId: string, roles: IRole[]): Observable<MemberState> {
    const body = {
      roles: roles.map(role => role.id)
    };
    return this.resourceHttpClient.post<MemberState>('/projects/' + projectId + '/members/' + memberId, body);
  }

  // ----------------------------- MEMBERS SECTION END ----------------------------------

  // ----------------------------- ROLES SECTION START ----------------------------------

  public postProjectMemberRoleObs(projectId: string, memberId: string, roleId: string): Observable<IMember> {
    const body = {
      role: roleId
    };
    return this.resourceHttpClient.post<IMember>('/projects/' + projectId + '/members/' + memberId + '/roles', body);
  }

  public deleteProjectMemberRoleObs(projectId: string, memberId: string, roleId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/projects/' + projectId + '/members/' + memberId + '/roles/' + roleId);
  }

  public getRoleForProject(project: ProjectOld): Promise<IRole> {
    return new Promise<IRole>(resolve => {
      this.resourceHttpClient
        .get<IRole>(project.customer?.resourceServer + '/projects/' + project.id + '/role')
        .subscribe(role => {
          resolve(role);
        });
    });
  }

  public getAllRolesObs(): Observable<IRole[]> {
    return this.organizationObs.pipe(
      switchMap(org => this.resourceHttpClient.get<IRole[]>(org?.api + 'projects/roles'))
    );
  }

  // ----------------------------- ROLES SECTION END ----------------------------------

  public getCompany(organization: IOrganization, companyId: string): Observable<ICompany> {
    return this.resourceHttpClient.get<ICompany>(organization.api + 'companies/' + companyId);
  }

  public getAuthoritiesForProject(baseResRoute: string, projectId: string): Promise<IAuthority[]> {
    return new Promise<IAuthority[]>(resolve => {
      this.resourceHttpClient
        .get<IAuthority[]>(baseResRoute + '/projects/' + projectId + '/authorities')
        .subscribe(auths => {
          resolve(auths);
        });
    });
  }

  public getPossibleRolesForProject(organization: IOrganization, project: ProjectOld): Promise<IRole[]> {
    return new Promise<IRole[]>(resolve => {
      this.resourceHttpClient.get<IRole[]>(organization.api + 'roles').subscribe(roles => resolve(roles));
    });
  }

  public getUsers(): Observable<IUser[]> {
    return this.resourceHttpClient.get<IUser[]>('/users');
  }

  public getUser(userId: string): Observable<IUser> {
    return this.resourceHttpClient.get<IUser>('/users/' + userId);
  }

  public getUserDetails(userIds: string[]): Promise<IUser[]> {
    return new Promise<IUser[]>(resolve => {
      let paramStr = '';
      for (const id of userIds) {
        paramStr = paramStr + id + ',';
      }
      paramStr = paramStr.substr(0, paramStr.length - 1);
      this.resourceHttpClient.get<IUser[]>(this.MANAGEMENT_BASE_URL + 'getusers/?ids=' + paramStr).subscribe(users => {
        resolve(users);
      });
    });
  }

  public getEntries(organization: IOrganization, project: IProject, subsetAll = false) {
    return this.resourceHttpClient.get<IEntry[]>(
      `${organization.api}projects/${project.id}/entries${subsetAll ? '?subset_type=all' : ''}`
    );
  }

  public getEntriesSpan(project: IProject, start: Date, end: Date): Observable<IEntryInterface[]> {
    const params: HttpParams = new HttpParams()
      .set('start', this.datePipe.transform(start, 'yyyy-MM-dd')!)
      .set('end', this.datePipe.transform(end, 'yyyy-MM-dd')!);

    return this.resourceHttpClient.get<IEntryInterface[]>('/projects/' + project.id + '/entries', { params: params });
  }

  public getImagesSpan(project: IProject, start: Date, end: Date): Observable<StateImage[]> {
    const params: HttpParams = new HttpParams()
      .set('start', this.datePipe.transform(start, 'yyyy-MM-dd')!)
      .set('end', this.datePipe.transform(end, 'yyyy-MM-dd')!)
      .set('subset_type', 'all');

    return this.resourceHttpClient.get<StateImage[]>('/projects/' + project.id + '/images', { params: params });
  }

  public getProjectUsers(project: ProjectOld): Promise<IMember[]> {
    return new Promise<IMember[]>((resolve, reject) => {
      this.resourceHttpClient
        .get<IMember[]>(project.customer?.resourceServer + '/project/' + project.id + '/users')
        .subscribe({
          error: err => {
            reject();
          },
          next: projectUsers => {
            resolve(projectUsers);
          }
        });
    });
  }

  public getRoles(): Observable<IRole[]> {
    return this.resourceHttpClient.get<IRole[]>('/roles');
  }

  public getUserRoles(userId: string): Observable<IRole[]> {
    return this.resourceHttpClient.get<IRole[]>('/users/' + userId + '/roles');
  }

  public postUserRoles(userId: string, roles: { roles: string[] }): Observable<IRole[]> {
    return this.resourceHttpClient.post<IRole[]>('/users/' + userId + '/roles', roles);
  }

  public deleteUserRole(userId: string, roleId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/users/' + userId + '/roles/' + roleId);
  }

  public postRole(role: IRole): Observable<IRole> {
    return this.resourceHttpClient.post<IRole>('/roles', role);
  }

  public patchRole(roleId: string, role: IRole): Observable<IRole> {
    return this.resourceHttpClient.patch<IRole>('/roles/' + roleId, role);
  }

  public deleteRole(roleId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/roles/' + roleId);
  }

  public getPermissions(): Observable<IPermission[]> {
    return this.resourceHttpClient.get<IPermission[]>('/permissions');
  }

  public getProjectsPermissions(): Observable<IPermission[]> {
    return this.resourceHttpClient.get<IPermission[]>('/projects/permissions');
  }

  public getUsersPermissions(userId: string): Observable<IPermission[]> {
    return this.resourceHttpClient.get<IPermission[]>('/users/' + userId + '/permissions');
  }

  public getInvitations(): Observable<IInvitation[]> {
    return this.resourceHttpClient.get<IInvitation[]>('/invitations');
  }

  public deleteInvitation(invitationId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/invitations/' + invitationId);
  }

  public createInvitation(invitationRequest: IInvitationPostRequest): Observable<IInvitation> {
    return this.resourceHttpClient.post<IInvitation>('/invitations', invitationRequest);
  }

  public getCompanies(query?: string): Observable<ICompany[]> {
    let params = new HttpParams();
    if (query) {
      params = params.append('query', query);
    }

    return this.resourceHttpClient.get<ICompany[]>('/companies', { params: params });
  }

  public getCompanyProjects(organization: IOrganization, companyId: string): Observable<IProject[]> {
    return this.resourceHttpClient.get<IProject[]>(organization.api + 'companies/' + companyId + '/projects');
  }

  public postProjectCompany(project: ProjectOld, company: ICompany): Promise<ICompany> {
    return new Promise<ICompany>((resolve, reject) => {
      this.resourceHttpClient
        .post<ICompany>(project.customer?.resourceServer + '/project/' + project.id + '/company', company)
        .subscribe({
          error: err => {
            reject(err);
          },
          next: company => {
            resolve(company);
          }
        });
    });
  }

  public postProjectCompanies(project: IProject, companies: TestCompany[]): Observable<void> {
    const body = {
      companies: companies.map(company => company.id)
    };
    return this.resourceHttpClient.post<void>('/projects/' + project.id + '/companies', body);
  }

  public patchCompany(company: TestCompany): Observable<TestCompany> {
    const body = {
      name: company.name,
      description: company.description,
      phone: company.phone,
      email: company.email,
      address: company.address
    };
    return this.organizationObs.pipe(
      take(1),
      switchMap(org => this.resourceHttpClient.patch<TestCompany>(org?.api + 'companies/' + company.id, body))
    );
  }

  public postCompany(company: TestCompany): Observable<TestCompany> {
    const body = {
      name: company.name,
      description: company.description,
      phone: company.phone,
      email: company.email,
      address: company.address
    };
    return this.resourceHttpClient.post<TestCompany>('/companies', body);
  }

  public getAllEntryIdsForProject(baseResRoute: string, projectId: string): Promise<IDiaryEntryIdsResponse[]> {
    return new Promise<IDiaryEntryIdsResponse[]>(resolve => {
      this.resourceHttpClient
        .get<IDiaryEntryIdsResponse[]>(baseResRoute + 'projects/' + projectId + '/entries')
        .subscribe(entires => {
          resolve(entires);
        });
    });
  }
  public addDiaryEntry(organization: IOrganization, projectId: string, entry: IDiaryEntry): Promise<IDiaryEntry> {
    console.log('posting: ', entry);
    return new Promise<IDiaryEntry>((resolve, reject) => {
      entry['companyId'] = entry.company.id;
      this.resourceHttpClient
        .post<IDiaryEntry>(organization.api + 'projects/' + projectId + '/entries', entry)
        .subscribe({
          error: err => {
            reject(err);
          },
          next: data => {
            resolve(data);
          }
        });
    });
  }

  public patchDiaryEntry(organization: IOrganization, projectId: string, entry: IDiaryEntry): Promise<IDiaryEntry> {
    return new Promise<IDiaryEntry>((resolve, reject) => {
      entry['companyId'] = entry.company.id;
      this.resourceHttpClient
        .patch<IDiaryEntry>(organization.api + 'projects/' + projectId + '/entries' + entry.id, entry)
        .subscribe({
          error: err => {
            reject(err);
          },
          next: data => {
            resolve(data);
          }
        });
    });
  }

  public patchEntry(projectId: string, entryId: string, entry: IEntryDto): Observable<IEntryInterface> {
    return this.resourceHttpClient.patch<IEntryInterface>('/projects/' + projectId + '/entries/' + entryId, entry);
  }

  public postEntry(projectId: string, entry: IEntryDto): Observable<IEntryInterface> {
    return this.resourceHttpClient.post<IEntryInterface>('/projects/' + projectId + '/entries', entry);
  }

  // public postEntryNew(projectId: string, entry: Entry): Observable<Entry>{
  //   return this.resourceHttpClient.post<Entry>('/projects/'+projectId+'/entries', this.entryToDAO(entry));
  // }

  public closeDiaryEntry(project: ProjectOld, entry: IDiaryEntry): Promise<IDiaryEntry> {
    return new Promise<IDiaryEntry>((resolve, reject) => {
      this.resourceHttpClient
        .post<IDiaryEntry>(
          project.customer?.resourceServer + '/project/' + project.id + '/entry/' + entry.id + '/close',
          {}
        )
        .subscribe({
          error: err => {
            reject(err);
          },
          next: entry => {
            resolve(entry);
          }
        });
    });
  }

  public deleteEntry(projectId: string, entryId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/projects/' + projectId + '/diary/' + entryId);
  }

  /**
   * Gets all dates for the project, where are entries or images
   * ordered desc
   * @param baseResRoute
   * @param project_id
   * @returns array of dates
   */
  public getAllDatesForProject(baseResRoute: string, project_id: string): Promise<Date[]> {
    return new Promise<Date[]>(resolve => {
      this.resourceHttpClient.get<string[]>(baseResRoute + '/project/' + project_id + '/alldates').subscribe(resp => {
        // as response is a string array of type YYYY-mm-dd -> parse it to date
        const dates: Date[] = [];
        for (let i = 0; i < resp.length; i++) {
          dates.push(new Date(resp[i]));
        }
        resolve(dates);
      });
    });
  }

  public getPossibleRoles(baseResRoute: string): Observable<IRole[]> {
    return this.resourceHttpClient.get<IRole[]>(baseResRoute + '/projects/roles');
  }

  public postProjectUser(project: ProjectOld, user: IUser, role: IRole): Promise<IMember> {
    return new Promise<IMember>((resolve, reject) => {
      this.resourceHttpClient
        .post<IMember>(project.customer?.resourceServer + '/project/' + project.id + '/user/' + user.id, role)
        .subscribe({
          next: up => resolve(up),
          error: error => reject()
        });
    });
  }

  public getPdfs(projectId: string): Observable<IPdf[]> {
    return this.resourceHttpClient.get<IPdf[]>('/projects/' + projectId + '/pdf');
  }

  public getPdf(projectId: string, pdfId: string): Observable<IPdf> {
    return this.resourceHttpClient.get<IPdf>('/projects/' + projectId + '/pdf/' + pdfId);
  }

  public getRecentPdf(project: IProject, templateId: string): Observable<IPdf> {
    return this.resourceHttpClient.get<IPdf>('/projects/' + project.id + '/templates/' + templateId);
  }

  /**
   * @deprecated
   * @param project
   * @param templateId
   * @returns
   */
  public getRecentPdfPromise(project: IProject, templateId: string): Promise<IPdf> {
    return new Promise<IPdf>((resolve, reject) => {
      this.resourceHttpClient.get<IPdf>('/project/' + project.id + '/pdf/template/' + templateId).subscribe({
        next: pdf => resolve(pdf),
        error: error => reject(error)
      });
    });
  }

  public getPdfTemplates(): Observable<IPdfTemplate[]> {
    return this.resourceHttpClient.get<IPdfTemplate[]>('/templates');
  }

  public postPdf(baseResRoute: string, pdfRequest: IGeneratePdfRequest) {
    return this.resourceHttpClient.post<IPdf>(baseResRoute + '/pdf', pdfRequest);
  }

  public deletePdf(projectId: string, pdfId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>('/projects/' + projectId + '/pdf/' + pdfId);
  }

  // public downloadPdf(project: ProjectOld, pdfId: string) {
  //   window.open(this.organization.api + '/project/' + project.id + '/pdf/' + pdfId, '_blank', 'location=yes');
  // }

  public getPdfBlob(project: IProject, pdfId: string): Observable<Blob> {
    return this.resourceHttpClient
      .get('/projects/' + project.id + '/pdf/' + pdfId + '/file', { responseType: 'arraybuffer' })
      .pipe(map(data => new Blob([data], { type: 'application/pdf' })));
  }

  // public getDiaryEntry(project: ProjectOld, entryId: string): Promise<IDiaryEntry> {
  //   return new Promise<IDiaryEntry>((resolve, reject) => {
  //     this.resourceHttpClient
  //       .get<IDiaryEntry>(this.organization.api + 'projects/' + project.id + '/entries/' + entryId)
  //       .subscribe({
  //         next: entry => resolve(entry),
  //         error: error => reject(error)
  //       });
  //   });
  // }

  public closeEntry(projectId: string, entryId: string): Observable<IEntryInterface> {
    return this.resourceHttpClient.post<IEntryInterface>('/projects/' + projectId + '/diary/' + entryId + '/close', {});
  }

  public addProject(project: ProjectOld) {
    return new Promise<ProjectOld>(resolve => {
      this.resourceHttpClient
        .post<ProjectOld>(project.customer?.resourceServer + '/projects', project)
        .subscribe(ret => {
          resolve(ret);
        });
    });
  }

  public getImage(url: string, params?: any): Observable<Blob> {
    return this.resourceHttpClient.get(url, { responseType: 'blob', params });
  }

  public getImagesObs(organization: IOrganization, project: IProject, subsetAll = false): Observable<StateImage[]> {
    return this.resourceHttpClient.get<StateImage[]>(
      `${organization.api}projects/${project.id}/images${subsetAll ? '?subset_type=all' : ''}`
    );
  }

  public getAllImages(baseResRoute: string, project_id: string, includeEntryImages: boolean): Promise<IImage[]> {
    return new Promise<IImage[]>(resolve => {
      this.resourceHttpClient
        .get<IImage[]>(baseResRoute + 'projects/' + project_id + '/images/?includeentryimages=' + includeEntryImages)
        .subscribe(images => {
          resolve(images);
        });
    });
  }

  public getImageBlob(project: ProjectOld, imageId: string, resolution: 'lg' | 'sm'): Promise<Blob> {
    const url = project.customer?.resourceServer + '/projects/' + project.id + '/images/' + imageId;
    const headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.append('res', resolution);
    const options: any = { headers: headers, params: params };
    options['responseType'] = 'blob';
    return new Promise<any>(resolve => {
      this.resourceHttpClient.get<any>(url, options).subscribe(blob => {
        resolve(blob);
      });
    });
  }

  public getImageBlobObs(projectId: string, imageId: string, resolution: 'sm' | 'lg'): Observable<Blob> {
    return this.organizationObs.pipe(
      switchMap(organization => {
        return this.resourceHttpClient.get(organization?.api + 'projects/' + projectId + '/images/' + imageId, {
          responseType: 'blob'
        });
      }),
      map(response => new Blob([response], { type: 'image/jpeg' }))
    );
  }

  public postImage(
    projectId: string,
    image: IImageEntryRequest,
    file: Blob,
    entryId?: string
  ): Observable<IImageEntry> {
    const formData = new FormData();
    formData.append('file', file);
    const jsonBlob = new Blob([JSON.stringify(image)], {
      type: 'application/json'
    });
    formData.append('entry', jsonBlob);
    const url: string =
      entryId == undefined ? '/projects/' + projectId + '/diary' : '/projects/' + projectId + '/diary/' + entryId;
    return this.resourceHttpClient.post<IImageEntry>(url, formData);
  }

  // patchImage(projectId: string, imageId: string, body: IImagePatchRequest): Observable<IImageEntry> {
  //   return this.resourceHttpClient.patch<IImageEntry>(`/projects/${projectId}/images/${imageId}`, body);
  // }

  deleteImage(projectId: string, imageId: string): Observable<void> {
    return this.resourceHttpClient.delete<void>(`/projects/${projectId}/images/${imageId}`);
  }

  public registerUser(): Observable<any> {
    console.log('post');
    const samlpleuser = {
      email: 'test4',
      registration: '12345',
      first_name: 'test',
      last_name: 'test',
      password: 'testpw'
    };
    return this.resourceHttpClient.post(this.MANAGEMENT_BASE_URL + '/register', samlpleuser);
  }

  public getOrganization(id: string): Observable<IOrganization> {
    return this.httpClient.get<IOrganization>(this.MANAGEMENT_BASE_URL + '/organizations/' + id);
  }

  public getOrganizationMembers(organizationId: string): Promise<IUser[]> {
    return new Promise<IUser[]>((resolve, reject) => {
      this.httpClient
        .get<IUser[]>(this.MANAGEMENT_BASE_URL + '/organizations/' + organizationId + '/members')
        .subscribe({
          error: err => {
            reject(err);
          },
          next: data => {
            resolve(data);
          }
        });
    });
  }

  public getOrganizationMembersObs(organizationId: string): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(this.MANAGEMENT_BASE_URL + '/organizations/' + organizationId + '/users');
  }

  public getOrganizationUsers(organizationId?: string): Observable<IUser[]> {
    if (organizationId) {
      return this.httpClient.get<IUser[]>(this.MANAGEMENT_BASE_URL + '/organizations/' + organizationId + '/users');
    }
    return this.organizationObs.pipe(
      tap(val => console.log('organizationObs : ', val)),
      filter(org => org != undefined),
      switchMap(org =>
        this.resourceHttpClient.get<IUser[]>(this.MANAGEMENT_BASE_URL + '/organizations/' + org?.id + '/users')
      )
    );
  }

  public getLicenses(organizationId: string): Observable<ILicense[]> {
    return of([]);
    //return this.resourceHttpClient.get<License[]>(this.MANAGEMENT_BASE_URL + "/organizations/"+organizationId+"/licenses")
  }

  public getUserInfo(userId: string | undefined): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      let obs: Observable<IUser> | undefined = undefined;
      if (userId != undefined) {
        obs = this.httpClient.get<IUser>(this.MANAGEMENT_BASE_URL + '/user/' + userId);
      } else {
        obs = this.httpClient.get<IUser>(this.MANAGEMENT_BASE_URL + '/user');
      }
      obs.subscribe({
        error: err => {
          reject();
        },
        next: user => {
          resolve(user);
        }
      });
    });
  }

  public postNotificationToken(fcmToken: string): Promise<IFCMToken> {
    const body = {
      token: fcmToken,
      clientType: 'browser'
    };
    return new Promise<IFCMToken>((resolve, reject) => {
      this.httpClient.post<IFCMToken>(this.MANAGEMENT_BASE_URL + '/notification/token', body).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  public getNotifications(): Promise<INotification[]> {
    return new Promise<INotification[]>((resolve, reject) => {
      this.httpClient.get<INotification[]>(this.MANAGEMENT_BASE_URL + '/notifications').subscribe({
        error: err => {
          reject();
        },
        next: notifications => {
          resolve(notifications);
        }
      });
    });
  }

  public getNotification(notificationId: string): Promise<INotification> {
    return new Promise<INotification>((resolve, reject) => {
      this.httpClient.get<INotification>(this.MANAGEMENT_BASE_URL + '/notification/' + notificationId).subscribe({
        error: err => {
          reject();
        },
        next: notification => {
          resolve(notification);
        }
      });
    });
  }
}
