import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentConfig, FormDialogComponent, IPermission, IRole, RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';
import { Observable } from 'rxjs';

export interface EditRoleDialogData {
  role: IRole | undefined;
}

export interface EditRoleFormGroup {
  name: FormControl<string | null | undefined>;
  description: FormControl<string | null | undefined>;
  selectedPermissions: FormControl<string[] | null>;
}

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss']
})
export class EditRoleDialogComponent
  extends FormDialogComponent<EditRoleDialogData, IRole, EditRoleFormGroup>
  implements OnInit
{
  permissions: IPermission[] = [];
  loading = true;

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService, private formBuilder: FormBuilder) {
    super();
    this.loadPageData();
  }

  get config(): ComponentConfig {
    return {
      header: `Rolle ${this.data?.role?.id ? 'bearbeiten' : 'erstellen'}`,
      confirmSuccessMessage: 'Rolle gespeichert',
      confirmErrorMessage: 'Rolle konnte nicht gespeichert werden',
      confirmLabel: 'Speichern'
    };
  }

  buildFormGroup(): FormGroup<EditRoleFormGroup> {
    return this.formBuilder.group({
      name: new FormControl(this.data?.role?.name, [Validators.required]),
      description: new FormControl(this.data?.role?.description),
      selectedPermissions: new FormControl(this.data?.role?.permissions ?? [], [Validators.required])
    });
  }

  unselectPermission(permission: IPermission) {
    const permissions = this.formGroup?.controls.selectedPermissions.value ?? [];
    const index = permissions.findIndex(id => permission.id === id);
    if (index >= 0) {
      permissions.splice(index, 1);
      this.formGroup?.controls.selectedPermissions.setValue(permissions);
    }
  }

  selectPermission(permission: IPermission) {
    const permissions = this.formGroup?.controls.selectedPermissions.value ?? [];
    if (!permissions.includes(permission.id)) {
      permissions.unshift(permission.id);
      this.formGroup?.controls.selectedPermissions.setValue(permissions);
    }
  }

  loadPageData() {
    this.loading = true;
    this.resourceService.getPermissions().subscribe({
      error: err => {
        this.permissions = [];
        this.loading = false;
      },
      next: permissions => {
        this.permissions = permissions.sort((a, b) => a.name.localeCompare(b.name));
        this.loading = false;
      }
    });
  }

  getSaveObs(): Observable<IRole> {
    const role: any = {
      name: this.formGroup?.controls.name.value,
      description: this.formGroup?.controls.description.value,
      permissions: this.formGroup?.controls.selectedPermissions.value
    };

    return this.data?.role?.id === undefined
      ? this.resourceService.postRole(role)
      : this.resourceService.patchRole(this.data.role.id, role);
  }
}
