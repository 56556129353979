import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectExportComponent } from './project-export.component';
import { ProjectExportRoutingModule } from './project-export-routing.module';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { ProjectExportTemplateItemComponent } from './project-export-template-item/project-export-template-item.component';
import { ProjectExportViewerComponent } from './project-export-viewer/project-export-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProjectExportRoutingModule,
    TooltipModule,
    ButtonModule,
    NgxExtendedPdfViewerModule,
    ProgressSpinnerModule
  ],
  declarations: [ProjectExportComponent, ProjectExportTemplateItemComponent, ProjectExportViewerComponent]
})
export class ProjectExportModule {}
