import { DialogComponent, DialogContainerComponent, DialogContainerData, ImageViewerComponentData } from 'shared';
import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-dialog-container',
  templateUrl:
    './../../../../../../libs/shared/src/lib/modules/dialog/dialog-container/dialog-container.component.html',
  styleUrls: ['./../../../../../../libs/shared/src/lib/modules/dialog/dialog-container/dialog-container.component.scss']
})
export class WebDialogContainerComponent<C extends DialogComponent<D, R>, D, R> extends DialogContainerComponent<
  C,
  D,
  R
> {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: DialogContainerData<C, D>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public matSheetData: DialogContainerData<C, D>,
    @Optional() public matDialogRef: MatDialogRef<WebDialogContainerComponent<C, D, R>, R>,
    @Optional() public matSheetRef: MatBottomSheetRef<WebDialogContainerComponent<C, D, R>, R>
  ) {
    super();
  }

  override dismiss(data: R) {
    if (this.matDialogRef) {
      this.matDialogRef.close(data);
    }
    if (this.matSheetRef) {
      this.matSheetRef.dismiss(data);
    }
  }

  override init() {
    if (this.matDialogData != undefined) {
      this.componentType = this.matDialogData.componentType;
      this.data = this.matDialogData.data;
    }
    if (this.matSheetData != undefined) {
      this.componentType = this.matSheetData.componentType;
      this.data = this.matSheetData.data;
    }
  }
}
