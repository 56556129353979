import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SharedModule, SharedPageModule } from 'shared';
import { DropzoneModule } from './modules/dropzone/dropzone.module';
import { SelectableModule } from './modules/select/selectable.module';
import { DiaryDateComponent } from './components/diary-date/diary-date.component';
import { DiaryEntryComponent } from './components/diary-entry/diary-entry.component';
import { CreatorChipComponent } from './components/creator-chip/creator-chip.component';
import { ImagePreviewListComponent } from './components/image-preview-list/image-preview-list.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImageViewerDescriptionComponent } from './components/image-viewer-description/image-viewer-description.component';
import { MatDividerModule } from '@angular/material/divider';
import { AbstractDiaryDialogComponent } from './components/abstract-diary-dialog/abstract-diary-dialog.component';
import { RegularDiaryEntryFormComponent } from './components/abstract-diary-dialog/regular-diary-entry-form/regular-diary-entry-form.component';
import { ObservantDiaryEntryFormComponent } from './components/abstract-diary-dialog/observant-diary-entry-form/observant-diary-entry-form.component';
import { LazyDropdownComponent } from './modules/lazy-dropdown/lazy-dropdown.component';
import { TouchedDirtyDirective } from 'shared';
import { EntryCastPipe } from './components/diary-date/entry-cast.pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { ImageUploadDialogComponent } from './components/image-upload-dialog/image-upload-dialog.component';
import { BlobToObjectUrlPipe } from './components/image-upload-dialog/blob-to-object-url.pipe';
import { DataAccessModule } from '../../../../data-access/src/lib/modules/data-access.module';
import { LazyImageLoadingPipe } from './components/image-viewer/lazy-image-loading.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTabsModule,
    MatGridListModule,
    ReactiveFormsModule,
    SharedPageModule,
    ReactiveFormsModule,
    DropzoneModule,
    NgOptimizedImage,
    SelectableModule,
    DropdownModule,
    CalendarModule,
    InputNumberModule,
    InputTextareaModule,
    RippleModule,
    SharedModule,
    MatDividerModule,
    LazyDropdownComponent,
    TouchedDirtyDirective,
    ProgressSpinnerModule,
    TooltipModule,
    DataAccessModule
  ],
  declarations: [
    DiaryDateComponent,
    ImagePreviewListComponent,
    DiaryEntryComponent,
    ImageViewerComponent,
    CreatorChipComponent,
    ImageViewerDescriptionComponent,
    AbstractDiaryDialogComponent,
    RegularDiaryEntryFormComponent,
    ObservantDiaryEntryFormComponent,
    ImageUploadDialogComponent,
    EntryCastPipe,
    BlobToObjectUrlPipe,
    LazyImageLoadingPipe
  ],
  providers: [
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ],
  exports: [
    DiaryDateComponent,
    ImagePreviewListComponent,
    DiaryEntryComponent,
    ImageViewerComponent,
    MatIconModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    LazyImageLoadingPipe
  ]
})
export class FeatureDiaryModule {}
