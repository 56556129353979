import { Directive, EventEmitter, Input, Output } from '@angular/core';

export interface ConfirmDialogData {
  confirmTitle: string;
  confirmMessage: string | undefined;
  confirmLabel: string;
  cancelLabel: string;
  confirmChallenge: string | undefined;
  confirmToggle: boolean;
  confirmDanger: boolean;
}

@Directive({ selector: '[onConfirmed]' })
export abstract class ConfirmDirective {
  @Output() onConfirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() confirmTitle = 'Aktion bestätigen';
  @Input() confirmMessage?: string;
  @Input() confirmLabel = 'Bestätigen';
  @Input() cancelLabel = 'Abbrechen';
  @Input() confirmChallenge?: string;
  @Input() confirmToggle = false;
  @Input() confirmDanger = false;
  @Input() confirmDisabled = false;

  abstract confirmHandle(dialogData: ConfirmDialogData): void;
}
