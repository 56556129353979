import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ConfirmDirective, ConfirmDialogData } from 'shared';

@Directive({ selector: '[onConfirmed]' })
export class BaseConfirmDirective implements OnInit {
  @Output() onConfirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() confirmTitle = 'Aktion bestätigen';
  @Input() confirmMessage?: string;
  @Input() confirmLabel = 'Bestätigen';
  @Input() cancelLabel = 'Abbrechen';
  @Input() confirmChallenge?: string;
  @Input() confirmToggle = false;
  @Input() confirmDanger = false;
  @Input() confirmDisabled = false;

  constructor(protected elementRef: ElementRef, public directiveImpl: ConfirmDirective) {
    this.directiveImpl.onConfirmed = this.onConfirmed;
    this.directiveImpl.onCanceled = this.onCanceled;
    this.directiveImpl.confirmTitle = this.confirmTitle;
    this.directiveImpl.confirmMessage = this.confirmMessage;
    this.directiveImpl.confirmLabel = this.confirmLabel;
    this.directiveImpl.cancelLabel = this.cancelLabel;
    this.directiveImpl.confirmChallenge = this.confirmChallenge;
    this.directiveImpl.confirmToggle = this.confirmToggle;
    this.directiveImpl.confirmDanger = this.confirmDanger;
    this.directiveImpl.confirmDisabled = this.confirmDisabled;
  }

  ngOnInit() {
    this.initListener();
  }

  initListener(): void {
    const dialogData: ConfirmDialogData = {
      confirmTitle: this.confirmTitle,
      confirmMessage: this.confirmMessage,
      confirmLabel: this.confirmLabel,
      cancelLabel: this.cancelLabel,
      confirmChallenge: this.confirmChallenge,
      confirmDanger: this.confirmDanger,
      confirmToggle: this.confirmToggle
    };
    fromEvent(this.elementRef.nativeElement, 'click').subscribe(() => {
      if (!this.confirmDisabled) {
        this.directiveImpl.confirmHandle(dialogData);
      } else {
        this.onConfirmed.emit();
      }
    });
  }
}
