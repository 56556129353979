import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogData, DialogComponent } from 'shared';
import { ComponentConfig } from '../../dialog/dialog/dialog.component';
import { EMPTY, Observable } from 'rxjs';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends DialogComponent<ConfirmDialogData, boolean> implements OnInit {
  get confirmTitle() {
    return this.data?.confirmTitle ?? 'confirmTitle';
  }
  get confirmMessage() {
    return this.data?.confirmMessage;
  }

  confirmControl?: FormGroup;
  get confirmToggle() {
    return this.data?.confirmToggle;
  }
  get confirmChallenge() {
    return this.data?.confirmChallenge?.toUpperCase();
  }

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.confirmControl = this.formBuilder.group({
      challenge: new FormControl<string>(
        '',
        this.confirmChallenge !== undefined ? [Validators.pattern(this.confirmChallenge), Validators.required] : []
      ),
      toggle: new FormControl<boolean>(false, this.confirmToggle ? [Validators.requiredTrue] : [])
    });
  }

  override confirm(): void {
    if (this.canConfirm()) {
      // Close the dialog, return true
      this.dismiss(true);
    } else {
      this.confirmControl?.markAllAsTouched();
    }
  }

  override close(): void {
    // Close the dialog, return false
    this.dismiss(false);
  }

  override canConfirm() {
    return this.confirmControl?.valid ?? false;
  }

  get config(): ComponentConfig {
    return {
      header: this.data!.confirmTitle,
      confirmLabel: this.data!.confirmLabel,
      showConfirmIcon: false,
      cancelLabel: this.data!.cancelLabel,
      showCancelIcon: false,
      confirmButtonClass: 'p-button-danger'
    };
  }

  getSaveObs(): Observable<boolean> {
    return EMPTY;
  }
}
