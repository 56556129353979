import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogComponent, DiaryEntryType, IEntryDialogData, IEntryInterface } from 'shared';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  IAbstractDiaryEntryFormGroup,
  IDiaryEntryFormGroupTypes,
  TForm
} from '../abstract-diary-entry-form/abstract-entry-form-groups.interface';
import { AbstractDiaryFactoryService } from '../abstract-diary-entry-form/abstract-diary-factory.service';
import { DatePipe } from '@angular/common';
import { distinctUntilChanged, EMPTY, map, Observable } from 'rxjs';
import { AbstractDiaryEntryForm } from '../abstract-diary-entry-form/abstract-diary-entry-form.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectSelectedProject } from 'data-access';
import { AddEntry, UpdateEntry } from 'data-access';
import { ComponentConfig } from 'libs/shared/src/lib/modules/dialog/dialog/dialog.component';

@Component({
  selector: 'app-abstract-diary-dialog',
  templateUrl: './abstract-diary-dialog.component.html',
  styleUrls: ['./abstract-diary-dialog.component.scss']
})
export class AbstractDiaryDialogComponent extends DialogComponent<IEntryDialogData, IEntryInterface> implements OnInit {
  public config: ComponentConfig = {
    header: 'Eintrag',
    confirmLabel: 'Speichern',
    confirmSuccessMessage: 'Eintrag gespeichert',
    confirmErrorMessage: 'Eintrag konnte nicht gespeichert werden'
  };

  abstractEntryFormGroup?: TForm<IAbstractDiaryEntryFormGroup<IDiaryEntryFormGroupTypes>>;
  entryFormGroups: Map<DiaryEntryType, TForm<IDiaryEntryFormGroupTypes>> = new Map();
  selectedEntryType?: DiaryEntryType;

  @ViewChild('entryFormContainer') entryFormContainer!: AbstractDiaryEntryForm<any, any, any>;

  constructor(private diaryFactory: AbstractDiaryFactoryService, private fb: FormBuilder, private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.abstractEntryFormGroup = this.buildAbstractEntryFormGroup();

    this.store.select(selectSelectedProject).subscribe(project => {
      if (project) {
        this.abstractEntryFormGroup!.controls.projectId.setValue(project.id);
      }
    });
    this.abstractEntryFormGroup.valueChanges
      .pipe(
        map(formData => formData.type),
        distinctUntilChanged()
      )
      .subscribe(type => {
        if (type) {
          this.selectedEntryType = type;
          if (!this.entryFormGroups.has(type)) {
            if (this.entry) {
              this.entryFormGroups.set(type, this.diaryFactory.buildFormGroup(this.entry));
            } else {
              this.entryFormGroups.set(type, this.diaryFactory.buildInitialFormGroup(type));
            }
          }
          if (this.entryFormGroups.has(type)) {
            this.abstractEntryFormGroup!.setControl('entry', this.entryFormGroups.get(type)!);
          }
        }
      });
    this.abstractEntryFormGroup.controls.type.setValue(this.entry?.type ?? DiaryEntryType.REGULAR, { emitEvent: true });
    this.abstractEntryFormGroup.controls.id.setValue(this.entry?.id);
    this.abstractEntryFormGroup.controls.date.setValue(this.entry?.date ?? this.data?.date);
  }

  protected buildAbstractEntryFormGroup(): TForm<IAbstractDiaryEntryFormGroup<IDiaryEntryFormGroupTypes>> {
    return this.fb.group({
      id: new FormControl<string | undefined | null>(undefined),
      projectId: new FormControl<string | undefined | null>(undefined, [Validators.required]),
      date: new FormControl<Date | undefined | null>(undefined, [Validators.required]),
      type: new FormControl<DiaryEntryType | undefined | null>(undefined, [Validators.required])
    }) as TForm<IAbstractDiaryEntryFormGroup<IDiaryEntryFormGroupTypes>>;
  }

  protected get entry(): IEntryInterface | undefined {
    return this.data?.entry as IEntryInterface;
  }

  public get entryType(): DiaryEntryType | null | undefined {
    return this.abstractEntryFormGroup!.get('type')?.value;
  }

  public get headerText(): string {
    // return `Eintrag ${this.datePipe.transform(this.date, 'dd.MM.YY')}`;
    return `Eintrag`;
  }

  override close(entry?: IEntryInterface) {
    this.dismiss(entry);
  }

  protected get entryTypeOptions(): {
    label?: string;
    value: DiaryEntryType;
    disabled?: boolean;
  }[] {
    return [
      {
        label: this.getEntryTypeLabel(DiaryEntryType.REGULAR),
        value: DiaryEntryType.REGULAR
      },
      {
        label: this.getEntryTypeLabel(DiaryEntryType.OBSERVANT),
        value: DiaryEntryType.OBSERVANT,
        disabled: true
      }
    ];
  }

  protected getEntryTypeLabel(entryType: DiaryEntryType) {
    switch (entryType) {
      case DiaryEntryType.REGULAR:
        return 'Regulärer Eintrag';
      case DiaryEntryType.OBSERVANT:
        return 'Observatorischer Eintrag';
    }
    return undefined;
  }

  getSaveObs(): Observable<IEntryInterface> {
    return this.entryFormContainer.saveEntry();
  }

  protected override confirmSuccessHandle(value: IEntryInterface) {
    if (this.entry?.id) {
      this.store.dispatch(new UpdateEntry(value));
    } else {
      this.store.dispatch(new AddEntry(value));
    }
    super.confirmSuccessHandle(value);
  }

  protected override canConfirm(): boolean {
    if (this.abstractEntryFormGroup!.invalid) {
      this.abstractEntryFormGroup!.markAllAsTouched();
    }
    return this.abstractEntryFormGroup!.valid;
  }
}
