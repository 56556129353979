import { Directive, Inject } from '@angular/core';
import { ConfirmDirective, ConfirmDialogData, DialogService, DIALOG_SERVICE_KEY } from 'shared';
import { ConfirmDialogComponent } from 'shared';

@Directive({ selector: '[onConfirmed]' })
export class WebConfirmDialogDirective extends ConfirmDirective {
  constructor(@Inject(DIALOG_SERVICE_KEY) private dialog: DialogService) {
    super();
  }

  override confirmHandle(dialogData: ConfirmDialogData) {
    this.dialog
      .openDialog<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, dialogData)
      .subscribe(confirmed => {
        if (confirmed) {
          this.onConfirmed.emit();
        } else {
          this.onCanceled.emit();
        }
      });
  }
}
