import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { DialogComponent } from './dialog/dialog.component';

@Injectable({ providedIn: 'root' })
export abstract class DialogService {
  abstract openDialog<C extends DialogComponent<D, R>, D, R>(
    component: ComponentType<C>,
    data: D | undefined
  ): Observable<R | undefined>;
}
