import { Observable, of, switchMap, combineLatest, BehaviorSubject, tap } from 'rxjs';
import { IPermission, RESOURCE_KEY, USER_KEY } from 'shared';
import { Inject, Injectable } from '@angular/core';
import { ResourceService, UserService } from 'data-access';
import { Store } from '@ngrx/store';
import { selectSelectedOrganization } from 'data-access';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissions: BehaviorSubject<IPermission[] | undefined> = new BehaviorSubject<IPermission[] | undefined>(
    undefined
  );

  constructor(
    @Inject(USER_KEY) private userService: UserService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store
  ) {
    this.getPermissionsObs().subscribe(permissions => {
      this.permissions.next(permissions);
    });
  }

  private getPermissionsObs(): Observable<IPermission[] | undefined> {
    return combineLatest([this.userService.getUser(), this.store.select(selectSelectedOrganization)]).pipe(
      switchMap(([user, organization]) => {
        if (user?.id && organization) {
          return this.resourceService.getUsersPermissions(user.id);
        } else {
          return of(undefined);
        }
      })
    );
  }

  public getPermissions(): Observable<IPermission[] | undefined> {
    return this.permissions.asObservable();
  }
}
